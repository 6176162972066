var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height"},[_c('v-row',{staticClass:"fill-height",attrs:{"justify":"center","align-content":"center"}},[_c('v-card',{attrs:{"width":"80%"}},[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Login")])],1),_c('v-card-text',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"email",attrs:{"name":"email","disabled":_vm.isUpdating,"filled":!_vm.isMobile ? true : false,"label":"email","required":"","rules":_vm.emailRules},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"disabled":_vm.isUpdating,"filled":!_vm.isMobile ? true : false,"label":"password","type":_vm.showPassword ? 'text' : 'password',"required":"","append-icon":!_vm.showPassword
                                            ? 'mdi-eye-outline'
                                            : 'mdi-eye-off-outline',"rules":_vm.passwordRules},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('a',{class:{
                                    disabled:
                                        (_vm.$refs.email &&
                                            !_vm.$refs.email.validate()) ||
                                        !_vm.$refs.email
                                },attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.recover($event)}}},[_vm._v("recupera password")])],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":!_vm.valid},on:{"click":function($event){$event.stopPropagation();return _vm.doLogin($event)}}},[_vm._v("Accedi")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }