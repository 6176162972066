<template>
    <v-container class="fill-height">
        <v-row class="fill-height" justify="center" align-content="center">
            <v-card width="80%">
                <v-toolbar>
                    <v-btn icon @click.stop="$router.go(-1)">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>

                    <v-toolbar-title>Registrazione</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-form v-model="valid" ref="signUpForm">
                        <v-container>
                            <v-row class="d-flex justify-center">
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="firstName"
                                        :disabled="isUpdating"
                                        :filled="!isMobile ? true : false"
                                        label="Nome"
                                        :rules="notEmptyRule"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="lastName"
                                        :disabled="isUpdating"
                                        :filled="!isMobile ? true : false"
                                        label="Cognome"
                                        :rules="notEmptyRule"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="email"
                                        :disabled="isUpdating"
                                        :filled="!isMobile ? true : false"
                                        label="email"
                                        required
                                        :rules="emailRules"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="password"
                                        :disabled="isUpdating"
                                        :filled="!isMobile ? true : false"
                                        label="password"
                                        :type="
                                            showPassword ? 'text' : 'password'
                                        "
                                        required
                                        :append-icon="
                                            !showPassword
                                                ? 'mdi-eye-outline'
                                                : 'mdi-eye-off-outline'
                                        "
                                        @click:append="
                                            showPassword = !showPassword
                                        "
                                        :rules="confirmPasswordRule"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="confirmPassword"
                                        :disabled="isUpdating"
                                        :filled="!isMobile ? true : false"
                                        label="ripeti password"
                                        :type="
                                            showConfirmPassword
                                                ? 'text'
                                                : 'password'
                                        "
                                        required
                                        :append-icon="
                                            !showConfirmPassword
                                                ? 'mdi-eye-outline'
                                                : 'mdi-eye-off-outline'
                                        "
                                        @click:append="
                                            showConfirmPassword = !showConfirmPassword
                                        "
                                        :rules="confirmPasswordRule"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-checkbox
                                        v-model="accept"
                                        :disabled="isUpdating"
                                    >
                                        <template v-slot:label>
                                            <div>
                                                Accetto i
                                                    <a
                                                        target="_blank"
                                                        href="http://vuetifyjs.com"
                                                        @click.stop
                                                    >
                                                        Termini e le Condizioni
                                                    </a>
                                                    e l'
                                                    <a
                                                        target="_blank"
                                                        href="http://vuetifyjs.com"
                                                        @click.stop
                                                    >
                                                        Informativa sulla Privacy
                                                    </a>

                                            </div>
                                        </template>
                                    </v-checkbox>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        :disabled="!valid || !accept"
                        @click.stop="doRegister"
                        >Registrati</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
import * as snackBar from "../../store/actions/snackbar";
import * as loginActions from "../../store/actions/user";

import { mapActions } from "vuex";

import {
    emailRules,
    passwordRules,
    notEmptyRule
} from "../../utils/validators";

import { isMobile } from "../../mixins/utils";

export default {
    data: () => ({
        firstName: "",
        lastName: "",
        confirmPassword: "",
        email: "",
        emailRules: [],
        password: "",
        passwordRules: [],
        notEmptyRule: [],
        isUpdating: false,
        accept: false,
        valid: false,
        showPassword: false,
        showConfirmPassword: false
    }),

    mixins: [isMobile],

    computed: {
        passwordEquality() {
            return (
                (this.password.length > 0 &&
                    this.confirmPassword === this.password) ||
                "Le password non sono uguali"
            );
        },
        confirmPasswordRule() {
            let rules = Object.assign([], passwordRules);
            rules.push(this.passwordEquality);
            // console.log(rules);
            return rules;
        }
    },

    methods: {
        ...mapActions({
            showSnackbar: snackBar.SNACKBAR_SHOW,
            register: loginActions.USER_REGISTER
        }),

        doRegister() {
            this.register({ firstName: this.firstName, lastName: this.lastName, email: this.email, password: this.password }).then(
                () => {
                    console.log("ok");
                    this.$refs.signUpForm.reset()
                }
            );
        }
    },

    mounted() {
        this.emailRules = emailRules;
        this.passwordRules = passwordRules;
        this.notEmptyRule = notEmptyRule;
    }
};
</script>

<style></style>
