var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height"},[_c('v-row',{staticClass:"fill-height",attrs:{"justify":"center","align-content":"center"}},[_c('v-card',{attrs:{"width":"80%"}},[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Modifica Password")])],1),_c('v-card-text',[_c('v-container',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"filled":!_vm.isMobile ? true : false,"label":"email","required":"","rules":_vm.emailRules,"disabled":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"disabled":_vm.isUpdating,"filled":!_vm.isMobile ? true : false,"label":"password","type":_vm.showPassword
                                                ? 'text'
                                                : 'password',"required":"","append-icon":!_vm.showPassword
                                                ? 'mdi-eye-outline'
                                                : 'mdi-eye-off-outline',"rules":_vm.confirmPasswordRule},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"disabled":_vm.isUpdating,"filled":!_vm.isMobile ? true : false,"label":"ripeti password","type":_vm.showConfirmPassword
                                                ? 'text'
                                                : 'password',"required":"","append-icon":!_vm.showConfirmPassword
                                                ? 'mdi-eye-outline'
                                                : 'mdi-eye-off-outline',"rules":_vm.confirmPasswordRule},on:{"click:append":function($event){_vm.showConfirmPassword = !_vm.showConfirmPassword}},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":!_vm.canConfirm},on:{"click":function($event){$event.stopPropagation();return _vm.confirm($event)}}},[_vm._v("Conferma")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }