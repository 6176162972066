<template>
    <v-app>
        <v-main>
            <v-fade-transition mode="out-in">
                <router-view class="pa-4"/>
            </v-fade-transition>
        </v-main>
        <AppSnackbar></AppSnackbar>
    </v-app>
</template>
<script>
export const AppSnackbar = () => import('../../components/core/AppSnackbar')

export default {
    components: {
        AppSnackbar
    }
};
</script>

<style>
</style>
