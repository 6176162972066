<template>
    <v-container class="fill-height">
        <v-row class="fill-height" justify="center" align-content="center">
            <v-card width="80%">
                <v-toolbar>
                    <v-toolbar-title>Login</v-toolbar-title>
                    <!-- <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                fab
                                small
                                class="ma-2 white--text"
                                color="red"
                                v-bind="attrs"
                                v-on="on"
                                @click.stop="googleLogin"
                            >
                                <v-icon dark>mdi-google</v-icon>
                            </v-btn>
                        </template>
                        <span>accedi con Google</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                fab
                                small
                                class="ma-2 white--text"
                                color="#3b5998"
                                v-bind="attrs"
                                v-on="on"
                                @click.stop="facebookLogin"
                            >
                                <v-icon dark>mdi-facebook</v-icon>
                            </v-btn>
                        </template>
                        <span>accedi con Facebook</span>
                    </v-tooltip> -->
                </v-toolbar>
                <v-card-text>
                    <v-form v-model="valid">
                        <v-container>
                            <v-row class="d-flex justify-center">
                                <v-col cols="12">
                                    <v-text-field
                                        ref="email"
                                        name="email"
                                        v-model="email"
                                        :disabled="isUpdating"
                                        :filled="!isMobile ? true : false"
                                        label="email"
                                        required
                                        :rules="emailRules"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="password"
                                        :disabled="isUpdating"
                                        :filled="!isMobile ? true : false"
                                        label="password"
                                        :type="
                                            showPassword ? 'text' : 'password'
                                        "
                                        required
                                        :append-icon="
                                            !showPassword
                                                ? 'mdi-eye-outline'
                                                : 'mdi-eye-off-outline'
                                        "
                                        @click:append="
                                            showPassword = !showPassword
                                        "
                                        :rules="passwordRules"
                                    ></v-text-field>
                                </v-col>
                                <a
                                    @click.prevent="recover($event)"
                                    :class="{
                                        disabled:
                                            ($refs.email &&
                                                !$refs.email.validate()) ||
                                            !$refs.email
                                    }"
                                    href=""
                                    >recupera password</a
                                >
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="!valid" @click.stop="doLogin"
                        >Accedi</v-btn
                    >
                    <!-- <v-btn @click.stop="doRegister">Registrati</v-btn> -->
                </v-card-actions>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
import axios from "axios";

import * as snackBar from "../../store/actions/snackbar";
import * as loginActions from "../../store/actions/user";

import { mapActions } from "vuex";

import { emailRules, passwordRules } from "../../utils/validators";
import { isMobile } from "../../mixins/utils";

export default {
    data: () => ({
        email: "",
        emailRules: [],
        password: "",
        passwordRules: [],
        isUpdating: false,
        valid: false,
        showPassword: false
    }),

    mixins: [isMobile],

    methods: {
        ...mapActions({
            showSnackbar: snackBar.SNACKBAR_SHOW,
            login: loginActions.USER_DOLOGIN,
            reset: loginActions.USER_RESET
        }),

        recover(event) {
            this.reset({ email: this.email }).then(response => {
                this.showSnackbar(
                    "Ti abbiamo mandato una mail con le istruzioni!"
                );
            });
        },

        doLogin() {
            console.log(document.cookie)
            this.login({ Username: this.email, Password: this.password }).then(
                response => {
                    console.log(response)
                    if (response) {
                        this.$router.push({
                            name: "Farms",
                            params: { lang: this.$i18n.locale }
                        }).catch(() => {});
                    }
                }
            );
        },
        doRegister() {
            this.$router.push({
                name: "Signup",
                params: { lang: this.$i18n.locale }
            }).catch(() => {});
        },
        googleLogin() {
            window.location.href = "http://localhost:3333/auth/google";
        },
        facebookLogin() {
            window.location.href = "http://localhost:3333/auth/facebook";
        }
    },

    mounted() {
        this.emailRules = emailRules;
        this.passwordRules = passwordRules;
    }
};
</script>

<style scoped>
/* .social-icon {
    height: 16px;
}

.facebook {
    height: 36px;
    background-color: rgb(59, 89, 152);
} */

.disabled {
    cursor: not-allowed;
    color: gray;
}
</style>
