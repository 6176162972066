<template>
    <v-container class="fill-height">
        <v-row class="fill-height" justify="center" align-content="center">
            <v-card width="80%">
                <v-toolbar>
                    <v-toolbar-title>Conferma Registrazione</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-container>
                        <v-row
                            class="fill-height"
                            align-content="center"
                            justify="center"
                        >
                            <v-col class="text-h6 text-center" cols="12">
                                {{ text }}
                            </v-col>
                            <v-col cols="6" class="text-center">
                                <v-progress-circular
                                    v-if="checking"
                                    :size="70"
                                    :width="7"
                                    color="purple"
                                    indeterminate
                                ></v-progress-circular>
                                <v-icon x-large v-else>{{ statusIcon }}</v-icon>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="checking || error" @click.stop="doLogin"
                        >Login</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { wait } from "../../utils/utils";
import * as userActions from "../../store/actions/user";
export default {
    data: () => ({
        checking: false,
        error: false
    }),

    computed: {
        text() {
            return this.checking ? "stiamo verificando l'accesso" : this.error ? "qualcosa è andato storto": "complimenti, l'account è stato attivato";
        },
        statusIcon() {
            return this.error ? 'mdi-thumb-down' : 'mdi-thumb-up'
        }
    },

    methods: {
        ...mapActions({
            verify: userActions.USER_VERIFY
        }),
        doLogin() {
            this.$router.push({name: 'Login', params: {lang: this.$i18n.locale}}).catch(() => {})
        }
    },

    mounted() {
        const query = this.$route.query;

        console.log(query)

        if (
            typeof query === "object" &&
            query !== null &&
            "userId" in query &&
            "code" in query
        ) {
            this.checking = true;
            Promise.all([
                wait(3000),
                this.verify({
                    userId: this.$route.query.userId,
                    code: this.$route.query.code
                })
            ])
                .then(values => {
                    console.log(values);
                })
                .catch(errors => {
                    this.error = true
                })
                .finally(() => {
                    this.checking = false;
                });
        }
    }
};
</script>

<style></style>
