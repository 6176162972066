export const notEmptyRule = [
    v => !!v || "Valore richiesto"
];

export const emailRules = [
    v => !!v || "Valore richiesto",
    v => /.+@.+\..+/.test(v) || "email non valida"
];

export const passwordRules = [
    v => !!v || "Valore richiesto",
    v => /[0-9]+/.test(v) || "almeno un numero",
    v => /[a-z]+/.test(v) || "almeno una minuscola",
    v => /[A-Z]+/.test(v) || "almeno una maiuscola",
    v => /^\s*(?:\S\s*){7,32}$/.test(v) || "deve contere almeno 7 caratteri ma non più di 32",
    // v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
];
