<template>
    <v-container class="fill-height">
        <v-row class="fill-height" justify="center" align-content="center">
            <v-card width="80%">
                <v-toolbar>
                    <v-toolbar-title>Modifica Password</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-container>
                        <v-form v-model="valid">
                            <v-container>
                                <v-row class="d-flex justify-center">
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="email"
                                            :filled="!isMobile ? true : false"
                                            label="email"
                                            required
                                            :rules="emailRules"
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="password"
                                            :disabled="isUpdating"
                                            :filled="!isMobile ? true : false"
                                            label="password"
                                            :type="
                                                showPassword
                                                    ? 'text'
                                                    : 'password'
                                            "
                                            required
                                            :append-icon="
                                                !showPassword
                                                    ? 'mdi-eye-outline'
                                                    : 'mdi-eye-off-outline'
                                            "
                                            @click:append="
                                                showPassword = !showPassword
                                            "
                                            :rules="confirmPasswordRule"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="confirmPassword"
                                            :disabled="isUpdating"
                                            :filled="!isMobile ? true : false"
                                            label="ripeti password"
                                            :type="
                                                showConfirmPassword
                                                    ? 'text'
                                                    : 'password'
                                            "
                                            required
                                            :append-icon="
                                                !showConfirmPassword
                                                    ? 'mdi-eye-outline'
                                                    : 'mdi-eye-off-outline'
                                            "
                                            @click:append="
                                                showConfirmPassword = !showConfirmPassword
                                            "
                                            :rules="confirmPasswordRule"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="!canConfirm" @click.stop="confirm"
                        >Conferma</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { wait } from "../../utils/utils";
import * as userActions from "../../store/actions/user";

import {
    emailRules,
    passwordRules,
    notEmptyRule
} from "../../utils/validators";

import { isMobile } from "../../mixins/utils";

export default {
    data: () => ({
        checking: false,
        error: false,
        confirmPassword: "",
        email: "",
        emailRules: [],
        password: "",
        passwordRules: [],
        notEmptyRule: [],
        isUpdating: false,
        accept: false,
        valid: false,
        showPassword: false,
        showConfirmPassword: false,
        verified: false,
        salt: ''
    }),

    mixins: [isMobile],

    computed: {
        canConfirm() {
            return this.valid && !this.error && this.verified
        },
        passwordEquality() {
            return (
                (this.password.length > 0 &&
                    this.confirmPassword === this.password) ||
                "Le password non sono uguali"
            );
        },
        confirmPasswordRule() {
            let rules = Object.assign([], passwordRules);
            rules.push(this.passwordEquality);
            // console.log(rules);
            return rules;
        }
    },

    methods: {
        ...mapActions({
            changePassword: userActions.USER_CHANGE_PASSWORD,
            checksalt: userActions.USER_CHECKSALT
        }),
        confirm() {
            this.changePassword({ email: this.email, password: this.password, salt: this.salt})
                .then(response => {
                    this.$router.push({name: 'Login', params: {lang: this.$i18n.locale}})
                })
        }
    },

    mounted() {
        const query = this.$route.query;
        this.emailRules = emailRules;
        this.passwordRules = passwordRules;
        this.notEmptyRule = notEmptyRule;

        if (
            typeof query === "object" &&
            query !== null &&
            "email" in query &&
            "salt" in query
        ) {
            this.email = this.$route.query.email;
            this.salt = this.$route.query.salt;
            this.verified = true;
            // this.checking = true;
            // this.checksalt({
            //     email: this.$route.query.email,
            //     salt: this.$route.query.salt
            // })
            //     .then(response => {
            //         this.verified = true;
            //     })
            //     .catch(errors => {
            //         this.error = true;
            //     })
            //     .finally(() => {
            //         this.checking = false;
            //     });
        }
    }
};
</script>

<style></style>
